<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between" style="padding: 8px 16px">
        <div></div>
        <div class="d-flex align-items-center">
          <font-awesome-icon
            class="mr-2 font-medium-1 btn-icon"
            :icon="['fal', 'cog']"
            @click="
              () => {
                $bvModal.show('table-setting-modal');
              }
            "
          />
          <table-setting-modal
            :fields="fields"
            :excludedFields="excludedFields"
            @setExcludedFields="setExcludedFields"
          />
          <b-dropdown
            :text="itemTypes[currentItem].label"
            class="border"
            size="sm"
            variant="flat-dark"
            right
            toggle-class="my-dropdown-width-fixed-width"
          >
            <b-dropdown-item
              v-for="(value, index) in itemTypes"
              :key="index"
              @click="onClick(value, index)"
            >
              {{ value.label }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="custom-table-height" :style="tableCssVars">
        <component
          :key="currentItem + ' ' + tableKey"
          :fields="includedFields"
          :is="currentTable"
          :perPage="perPage"
          :currentPage="currentPage"
          :selected="selected"
          @setTotalRows="(total) => (totalRows = total)"
          @setCurrentPage="(page) => (currentPage = page)"
          @selectedChange="(newSelected) => (selected = newSelected)"
        >
        </component>
      </div>

      <div class="d-flex justify-content-between py-50 border-top font-small-3">
        <div class="my-auto ml-2">
          Show
          <b-dropdown
            :text="String(perPage)"
            variant="outline-secondary"
            toggle-class="custom-dropdown"
            class="dropdown-borderless"
          >
            <b-dropdown-item
              v-for="item in [10, 20, 50, 100]"
              :key="item"
              @click="perPage = item"
            >
              {{ item }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="d-flex">
          <div class="my-auto mr-2">
            Showing
            {{ Math.min(totalRows, (currentPage - 1) * perPage + 1) }} to
            {{ Math.min(currentPage * perPage, totalRows) }} of
            {{ totalRows }} entries
          </div>
          <b-pagination
            class="my-auto"
            size="sm"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <font-awesome-icon :icon="['far', 'chevron-left']" />
            </template>
            <template #next-text>
              <font-awesome-icon :icon="['far', 'chevron-right']" />
            </template>
          </b-pagination>
        </div>
        <div style="width: 200px" />
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from "@axios";
import { useRouter } from "@core/utils/utils";
import { BCard, BDropdown, BDropdownItem, BDropdownForm } from "bootstrap-vue";
import TableSettingModal from "@core/components/modals/TableSettingModal";

import LeadsTable from "./LeadsTable";
import tableFields from "./tableFields";
import unassignDropdown from "./unassignDropdown";
import { notifyError, notifySuccess } from "@/@core/utils";

export default {
  components: {
    BCard,
    BDropdown,
    BDropdownItem,
    BDropdownForm,
    LeadsTable,
    TableSettingModal,
  },

  data() {
    return {
      itemTypes: {
        leads: {
          label: "Leads",
          route: { name: "leads", query: { tab: "enable" } },
          table: "LeadsTable",
        },
        leads_archive: {
          label: "Archive",
          route: { name: "leads", query: { tab: "archive" } },
          table: "LeadsTable",
        },
      },
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      tableFields: tableFields(),
      unassignDropdown,
      isDropdown2Visible: false,
      excludedFields: [],
      selected: [],
      employees: [],
      tableKey: true,
      ibs_op: [],
      employees_op: [],
    };
  },

  computed: {
    currentItem() {
      if (this.$route.query.tab == "archive") return "leads_archive";
      return "leads";
    },
    currentTable() {
      return this.itemTypes[this.currentItem].table;
    },
    tableCssVars() {
      return {
        "--per-page": this.perPage,
      };
    },
    fields() {
      return this.tableFields[this.currentItem].filter((f) => f.settingLabel);
    },
    includedFields() {
      return this.tableFields[this.currentItem].filter(
        (f) => !this.excludedFields.includes(f.key)
      );
    },
  },

  methods: {
    search_ib_change(search, loading) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(search.toLowerCase()) || /^\d+$/.test(search))
        axios
          .get(
            `${localStorage.getItem(
              "VUE_APP_API_URL"
            )}/partners?email_or_id=${search}`
          )
          .then((res) => (this.ibs_op = res.data));
    },

    search_change(search, loading) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(search.toLowerCase()) || /^\d+$/.test(search))
        axios
          .get(
            `${localStorage.getItem(
              "VUE_APP_API_URL"
            )}/employees?email_or_id=${search}`
          )
          .then((res) => (this.employees_op = res.data));
    },

    onClick(value, index) {
      if (this.$route.query.tab != value.route.query.tab) {
        this.$router.push(value.route);
        this.resetExcludedFields();
      }
    },
    setExcludedFields(fields) {
      localStorage.setItem(
        "table_excluded_fields_" + this.currentItem,
        JSON.stringify(fields)
      );
      this.$router.go(0);
    },
    resetExcludedFields() {
      let fromLocalStorage = localStorage.getItem(
        "table_excluded_fields_" + this.currentItem
      );
      this.excludedFields = JSON.parse(fromLocalStorage) || [];
    },
    onClickAssign(action) {
      if (action.onclick) {
        this.$bvModal.show(action.onclick.modalID);
      }
    },
    assignLeadAM(item) {
      console.log("Selected: ", item);
      let loading = this.$loading.show();
      axios
        .post(`${localStorage.getItem("VUE_APP_API_URL")}/leads/assign`, {
          lead: {
            principal_type: "user",
            principal_id: item,
            lead_ids: this.selected,
          },
        })
        .then((res) => {
          notifySuccess("Success", "Successfully assigned leads to AM");
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError("Error", "Failed to assign leads");
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
    assignLeadIB(item) {
      console.log("Selected: ", item);
      let loading = this.$loading.show();
      axios
        .post(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/partners/${item}/assign_lead_to_ib`,
          {
            lead_ids: [this.selected],
          }
        )
        .then((res) => {
          notifySuccess("Success", "Successfully assigned leads to IB");
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError("Error", "Failed to assign leads");
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
    onClickUnassign(action) {
      if (action.onclick) {
        this[action.onclick.method](...(action.onclick.params || []));
      }
    },
    unassignLeadIB(item) {
      console.log("Selected: ", item);
      let loading = this.$loading.show();
      axios
        .post(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/partners/${item}/unassign_lead_from_ib`,
          {
            lead_ids: [this.selected],
          }
        )
        .then((res) => {
          notifySuccess("Success", "Successfully unassigned leads from IB");
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError("Error", "Failed to unassign leads");
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
    onclickAction(action) {
      if (action.onclick) {
        this[action.onclick.method](...action.onclick.params);
      }
    },
    changeLeadState(transition) {
      console.log("Selected: ", this.selected, " transit ", transition);
      let loading = this.$loading.show();
      axios
        .post(`${localStorage.getItem("VUE_APP_API_URL")}/leads/update_state`, {
          lead_ids: this.selected,
          transition,
        })
        .then((res) => {
          notifySuccess("Success", "Successfully changed state of leads");
          this.selected.splice(0);
          this.tableKey = !this.tableKey;
        })
        .catch((err) => {
          notifyError("Error", "Failed to changed state of leads");
          console.error(err);
        })
        .finally(() => {
          loading.hide();
        });
    },
  },

  mounted() {
    this.$root.$on("bv::dropdown::show", (bvEvent) => {
      if (bvEvent.componentId.includes("dropdown-2")) {
        this.isDropdown2Visible = true;
      }
    });
    this.$root.$on("bv::dropdown::hide", (bvEvent) => {
      if (bvEvent.componentId.includes("dropdown-2")) {
        this.isDropdown2Visible = false;
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault();
      }
    });
    this.resetExcludedFields();

    // axios.get(`${localStorage.getItem("VUE_APP_API_URL")}/employees`).then((res) => {
    //   this.employees = res.data.map((x) => {
    //     return {
    //       value: x.user.id,
    //       text: `${x.user.id} - ${x.user.full_name} - ${x.position_title}`
    //     }
    //   })
    // }).catch(err => {
    //   console.error(err)
    // }).finally(() => {
    // })

    // axios.get(`${localStorage.getItem("VUE_APP_API_URL")}/partners`).then(res => {
    //   this.ibs = []
    //   this.ibs.push(...res.data.map(x => ({
    //     text: `${x.id} - ${x.user.full_name} - ${x.commission_profile.name}`,
    //     value: x.id,
    //   })))
    // }).catch(err => {
    //   console.error(err)
    // }).finally(() => {

    // })
  },

  destroyed() {
    this.$store.commit("app/SET_SIDEBAR_WIDTH", 0);
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>

<style>
.children-dropdown {
  display: flex;
  padding: 0;
  justify-content: space-between;
  width: 100%;
}
</style>
