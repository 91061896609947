export default {
  leads: {
    // am: {
    //   label: "Account Manager",
    // },
    ib: {
      label: "IB",
      onclick: {
        method: "unassignLeadIB"
      }
    }
  },
}