<template>
  <common-table
    :items="leads"
    :fields="fields"
    :perPage="perPage"
    :currentPage="currentPage"
    :selected="selected"
    @fetchItems="fetchItems"
    :showLoading="showLoading"
    @setCurrentPage="(page) => $emit('setCurrentPage', page)"
    @selectedChange="(newSelected)  => {$emit('selectedChange', newSelected);}"
  />
</template>

<script>
import CommonTable from "@/components/table/CommonTable"
import {mapGetters} from 'vuex'
import { notifyError } from '@/@core/utils';

export default {
  components: {
    CommonTable
  },

  props: {
    fields: Array,
    perPage: Number,
    currentPage: Number,
    selected: Array,
  },

  data() {
    return {
      showLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      leads: "lead/leads",
    }),
  },

  methods: {
    fetchItems(size, page, sort_by, filter_by) {
      let params = {size, page, filter_by: filter_by, sort_by: sort_by}
      if (this.$route.query.tab == 'archive') {
        filter_by["state"] = {
          filter: "archived",
          type: "equals",
        }
      } else {
        filter_by["state"] = {
          filter: "archived,converted",
          type: "contains",
          is_reverse: true
        }
      }
      this.showLoading = true
      this.$emit("setSortBy", sort_by)
      this.$emit("setFilterBy", filter_by)
      this.$store.dispatch("lead/fetchLeads", params).then((res) => {
        this.$emit("setTotalRows", res.data.total)
      }).catch(err => {
        notifyError("Error", "Fail to fetch leads")
        console.error(err);
      }).finally(() => {
        this.showLoading = false
      })
    },
  },

  mounted() {
  },
};
</script>