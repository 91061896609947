import moment from "moment"
import { capitalize } from '@core/utils/filter'
import { formatMoney } from "@/helper/function"
import router from "@/router"

export default () => {
  let fields = {
    "leads": [
     
      // {
      //   key: "last_sign_in_at",
      //   settingLabel: "Last Sign In At",
      //   formatter: (value, key, item) => {
      //     return `${moment(item.user.last_sign_in_at).format('DD/MM/Y')} <small class='text-secondary'>${moment(item.user.last_sign_in_at).format('hh:mm:ss')}<small>`
      //   },
      //   sortable: true,
      //   sort_key: "users.last_sign_in_at",
      // },
      {
        key: "id", 
        label: "Lead ID", 
        settingLabel: "Lead ID",
        sortable: true,
        sort_key: "leads.id",
        filterMaxWidth: '65px',
        thClass: 'py-75 pl-2',
        tdClass: 'py-75 pl-2',
        filterClass: 'py-50',
      },
      {
        key: "full_name",
        label: "Name",
        settingLabel: "Name",
        sortable: true,
        sort_key: "users.first_name,users.middle_name,users.last_name",
       
      },
      {
        key: "email",
        settingLabel: "Email",
        formatter: (value, key, item) => {
          return item.user.email
        },
        sortable: true,
        sort_key: "users.email",
        onclick: (item) => {
          window.location.href = "mailto:" + item.user.email
        }
      },
      {
        key: "phone",
        settingLabel: "Phone",
        formatter: (value, key, item) => {
          return item.user.personal_info.mobile_phone
        },
        sortable: true,
        sort_key: "users.personal_info ->> 'mobile_phone'"
      },
      {
        key: "country",
        settingLabel: "Country",
        sortable: true,
        filterMaxWidth: '50px'
      },
      {
        key: "nationality",
        settingLabel: "Nationality",
        filterMaxWidth: '50px'
      },
      {
        key: "owner_id",
        label: "AM ID",
        settingLabel: "AM ID",
        formatter: (value, key, item) => {
          return `<div>${value ? value : "-"}</div>`;
        },
        filterMaxWidth: '60px'
      },
      {
        key: "ib_id",
        label: "IB ID",
        settingLabel: "IB ID",
        formatter: (value, key, item) => {
          return `<div>${value ? value : "-"}</div>`;
        },
        filterMaxWidth: '60px'
      },
      {
        key: "has_demo_account",
        label: "Demo",
        settingLabel: "Demo Account",
        formatter: (value, key, item) => {
          return value > 0 ? "Yes" : "No"
        },
        filterMaxWidth: '60px'
      },
      {
        key: "circle",
        label: "Circle",
        settingLabel: "Circle",
        filter: {
          type: "select",
          options: [
            {value: "All", text: "All"}, 
            {value: "Priority", text: "Priority"},
            {value: "Hot", text: "Hot"},
            {value: "Warm", text: "Warm"},
            {value: "Cold", text: "Cold"},
            {value: "New", text: "New"},
          ]
        },
      },
      {
        key: "2FA",
        settingLabel: "2FA",
        formatter: (value, key, item) => {
          return (item.user.settings.is_google_auth_enable || item.user.settings.is_sms_auth_enable) ? "Yes" : "No"
        },
        filter: {
          type: "select",
          options: [
            {value: "All", text: "All"}, 
            {value: "yes", text: "Yes"}, 
            {value: "no", text: "No"}
          ]
        },
      },
      {
        key: "KYC",
        settingLabel: "KYC",
        formatter: (value, key, item) => {
          if (item.status == 'No KYC') {
            return "Not Submitted"
          }
          return "Submitted"
        },
        filter: {
          type: 'none'
        }
      },
      {
        key: "ID",
        settingLabel: "ID",
        formatter: (value, key, item) => {
          return capitalize(item.document_front_img.status)
        },
        filter: {
          type: 'none'
        }
      },
      {
        key: "POR",
        settingLabel: "POR",
        formatter: (value, key, item) => {
          return capitalize(item.address_verification_img.status)
        },
        filter: {
          type: 'none'
        }
      },
      {
        key: "PP",
        settingLabel: "Portrait Photo",
        formatter: (value, key, item) => {
          return capitalize(item.portrait_img.status)
        },
        filter: {
          type: 'none'
        }
      },
      {
        key: "status",
        settingLabel: "Status",
        formatter: (value, key, item) => {
          let color = ""
          switch (value) {
            case "No KYC": 
              color = "text-danger"
              break;
            case "Client Verification":
              color = "text-success"
              break;
            default:
              color = "text-warning"
          }
          return `<div class="${color}">${value}</div>`
        },
        filter: {
          type: "select",
          options: [
            {value: "All", text: "All"}, 
            {value: "No KYC", text: "No KYC"}, 
            {value: "Missing Documents", text: "Missing Documents"},
            {value: "Documents Verification", text: "Documents Verification"},
            {value: "Client Verification", text: "Client Verification"},
          ]
        },
        thClass: 'pl-2',
        tdClass: 'pl-2',
      },
      {
        key: "state",
        settingLabel: "State",
        formatter: (value, key, item) => {
          let color = ""
          switch (value) {
            case "archived":
              color = "text-secondary"
              break
            case "disabled": 
              color = "text-danger"
              break;
            case "active":
              color = "text-success"
              break;
            default:
              color = "text-warning"
          }
          return `<div class="${color}">${value}</div>`
        },
        filter: {
          type: "select",
          options: [
            {value: "All", text: "All"}, 
            {value: "active", text: "Active"}, 
            {value: "locked", text: "Locked"},
            {value: "disabled", text: "Disabled"},
            {value: "archived", text: "Archived"},
          ]
        },
      },
      {
        key: "created_at",
        settingLabel: "Registration Date",
        label: "Registration Date",
        formatter: (value, key, item) => {
          return moment(value).format("DD/MM/Y")
        },
        filter: {
          type: "date",
          default: {
            filter: "",
            type: 'inRange',
            filterType: 'date'
          }
        },
        sortable: true,
        sort_key: "leads.created_at"
      },
      {
        key: "campaign",
        settingLabel: "Campaign",
        formatter: (value, key, item) => {
          return ''
        },
        filter: {
          type: 'none'
        }
      },
      {
        key: "filter",
        label: "",
        thStyle: "width: 30px; padding: none"
      }
    ],
  }
  fields = Object.assign(fields, {"leads_archive": fields['leads']})
  return fields
}